<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 功能名称 -->
					<el-form-item label="功能名称" prop="title">
						<div class="aaaa">
							<el-input v-model="form.title" placeholder="请填写功能名称" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 功能目录 -->
					<el-form-item label="功能目录" prop="catalogue">
						<div class="aaaa">
							<el-input v-model="form.catalogue" placeholder="请填写功能目录" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 功能类型 -->
					<el-form-item label="功能类型" prop="type">
						<el-select v-model="form.type" placeholder="请选择功能类型" size="large">
							<el-option label="中台" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="后台" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="小程序" value="3" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 规划时间 -->
					<el-form-item label="规划时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetimerange" range-separator="截至"
								start-placeholder="规划开始时间" end-placeholder="规划结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 开发状态 -->
					<el-form-item label="开发状态" prop="state">
						<el-select v-model="form.state" placeholder="请选择开发状态" size="large">
							<el-option label="待开发" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="开发中" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="已完成" value="3" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 重要程度 -->
					<el-form-item label="重要程度" prop="importanceLevel">
						<el-select v-model="form.importanceLevel" placeholder="请选择重要程度" size="large">
							<el-option label="普通功能" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="加急开发" value="2"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 排序 -->
					<el-form-item label="排序" prop="sort">
						<div class="aaaa">
							<el-input v-model="form.sort" placeholder="请填写排序值" size="large"></el-input>
						</div>
						<div class="referrals">
							（提示：大到小排序 默认100）
						</div>
					</el-form-item>
					<!-- 编辑器 -->
					<el-form-item prop="copyreader">
						<div class="subject">
							<span class="xingxing">*</span>
							主体内容
						</div>
						<div style="border: 1px solid #ccc; width:100%">
							<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
								:defaultConfig="toolbarConfig" :mode="mode" />

							<Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml"
								:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
			<!-- 所属工会选择 -->
			<div>
				<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
					<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
				</s3-layer>
			</div>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		Editor,
		Toolbar,
		Plus,
	} from '@wangeditor/editor-for-vue'
	import {
		ref,
		reactive,
		onMounted,
		shallowRef,
		onBeforeUnmount,
		onActivated,
	} from "vue";
	// import { Zzlist,Addlist} from '../../utils/api'
	import {
		functionInfo,
		functionEdit,
	} from '../../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import moments from 'moment'
	import * as imageConversion from 'image-conversion'
	import {
		useStore
	} from "vuex";
	export default {
		name: "manageEdit",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			//获取列表页传来的id
			const route = useRoute()
			let loading = ref(true)
			let spChildIndex = ref(0)
			let functionInfos = () => {
				// console.log(sessionStorage.getItem('processIds'))
				let data = {
					id: sessionStorage.getItem('processIds')
				}
				// var userNames = ref([])
				const id = ref([])
				functionInfo(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// 活动名称
						form.title = res.data.data.developmentInfo.title
						// 功能目录
						form.catalogue = res.data.data.developmentInfo.catalogue
						// 时间
						let staet = ref(moments(res.data.data.developmentInfo.startTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						let enables = ref(moments(res.data.data.developmentInfo.endTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						initiation.value = res.data.data.developmentInfo.startTime
						terminate.value = res.data.data.developmentInfo.endTime
						form.value1 = [staet.value, enables.value]
						//功能类型
						if (res.data.data.developmentInfo.type == 1) {
							form.type = '中台'
						} else if (res.data.data.developmentInfo.type == 2) {
							form.type = '后台'
						} else {
							form.type = '小程序'
						}
						//开发状态
						if (res.data.data.developmentInfo.state == 1) {
							form.state = '待开发'
						} else if (res.data.data.developmentInfo.state == 2) {
							form.state = '开发中'
						} else {
							form.state = '已完成'
						}
						//重要级别
						if (res.data.data.developmentInfo.importanceLevel == 1) {
							form.importanceLevel = '普通功能'
						} else if (res.data.data.developmentInfo.importanceLevel == 2) {
							form.importanceLevel = '加急开发'
						}
						//排序
						form.sort = res.data.data.developmentInfo.sort
						//编辑器内容
						valueHtml.value = res.data.data.developmentInfo.content

					}
				})
			}
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 开始时间
			let initiation = ref('')
			// 结束时间
			let terminate = ref('')
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),

			]
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					functionInfos()
				}
			})
			let texture = ref([])
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {

				if (valueHtml.value == '<p><br></p>') {
					form.copyreader = ''
				} else {
					form.copyreader = valueHtml.value
				}
				var b = ref(new Date(form.value1[0]))
				var begin = ref('')
				begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value.getDate() + ' ' +
					b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value.getSeconds();
				var start = ref(new Date(begin.value).getTime() / 1000)
				// 截至时间
				var d = ref(new Date(form.value1[1]))
				var finish = ref('')
				finish.value = d.value.getFullYear() + '-' + (d.value.getMonth() + 1) + '-' + d.value.getDate() + ' ' +
					d.value.getHours() + ':' + d.value.getMinutes() + ':' + d.value.getSeconds();
				var over = ref(new Date(finish.value).getTime() / 1000)
				//功能类型转换
				let types = ref(null)
				if (form.type == '中台') {
					types.value = 1
				} else if (form.type == '后台') {
					types.value = 2
				} else if (form.type == '小程序') {
					types.value = 3
				} else if (form.type == 1) {
					types.value = 1
				} else if (form.type == 2) {
					types.value = 2
				} else if (form.type == 3) {
					types.value = 3
				}
				//开发状态转换
				let states = ref(null)
				if (form.state == '待开发') {
					states.value = 1
				} else if (form.state == '开发中') {
					states.value = 2
				} else if (form.state == '已完成') {
					states.value = 3
				} else if (form.state == 1) {
					states.value = 1
				} else if (form.state == 2) {
					states.value = 2
				} else if (form.state == 3) {
					states.value = 3
				}
				//重要级别转换
				let importanceLevels = ref(null)
				if (form.importanceLevel == '普通功能') {
					importanceLevels.value = 1
				} else if (form.importanceLevel == '加急开发') {
					importanceLevels.value = 2
				} else if (form.importanceLevel == 1) {
					importanceLevels.value = 1
				} else if (form.importanceLevel == 2) {
					importanceLevels.value = 2
				}
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						id:sessionStorage.getItem('processIds'),
						// 功能名称
						title: form.title,
						//功能目录
						catalogue: form.catalogue,
						//选择开始和截止时间
						startTime: start.value,
						endTime: over.value,
						// 功能类型 
						type: types.value,
						//开发状态
						state:states.value,
						//重要级别
						importanceLevel: importanceLevels.value,
						//排序
						sort: form.sort,
						// 编辑器内容
						content: form.copyreader,

					}
					if (valid == true) {
						ConfirmLoading.value = true
						functionEdit(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'manageList',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {
				functionInfos()
			})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'delIndent', // 缩进
					'indent', // 增进
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			// 富文本上传图片地址
			// let fils=ref('')
			// placeholder: '请输入内容...',
			const editorConfig = {
				MENU_CONF: {}
			}
			// 视频上传
			editorConfig.MENU_CONF['uploadVideo'] = {
				// 上传视频的配置
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				// 限制上传视频大小
				maxFileSize: 104857600, // 100M
				//上传字段 
				fieldName: 'imageFile',
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 判断视频是否大于100M
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 104857600) {
						return true
					} else if (isLt2M > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError(file, res) {
					console.log(file)
					console.log(res)
					if (file.size > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
					} else {
						ElMessage({
							message: '上传失败，请稍后再试',
							type: "warning",
							center: true,
						});
					}
				},
			}


			// 图片上传
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置 
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// meta: {
				//   imageFile:fils
				// },
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			const visible = ref(false);
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				title: "",
				catalogue: "",
				value1: "",
				type: "",
				state: "",
				importanceLevel: "",
				copyreader: "",
			})
			// 验证表单提示
			const rules = {
				title: [{
					required: true,
					message: "请填写功能名称",
					trigger: "blur"
				}, ],
				catalogue: [{
					required: true,
					message: "请填写功能目录",
					trigger: "blur"
				}, ],
				value1: [{
					required: true,
					message: "请选择规划时间",
					trigger: "change"
				}, ],
				type: [{
					required: true,
					message: "请选择功能类型",
					trigger: "change"
				}, ],
				state: [{
					required: true,
					message: "请选择开发状态",
					trigger: "change"
				}, ],
				importanceLevel: [{
					required: true,
					message: "请选择重要级别",
					trigger: "change"
				}, ],
				copyreader: [{
					required: true,
					message: "请填写主题内容",
					trigger: "change"
				}, ],
			}
			return {
				// 按钮加载
				ConfirmLoading,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules,
				visible,
				texture,
				defaultTime2,
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}

	.a1.hover {
		color: #fff;
		background: #e6a23c;
	}

	.b1.hover {
		color: #fff;
		background: #409eff;
	}

	.c1.hover {
		color: #fff;
		background: #67c23a;
	}
</style>